import React, { Component } from 'react';
import TextEditor from '../../Components/Editor/TextEditor';
import axios from '../../axios';
import { withRouter } from 'react-router-dom';
import { imageUtils } from '../../Services/imageUtils';
import Spinner from '../../Components/Spinner';

class AboutEditPage extends Component {

    state = {
        isLoaded: false,
        htmlScript: null,
        editorIsLoaded: false,
        currentImages: [],
        newImages: [],
        oldImages: [],
    }

    componentDidMount() {
        axios.get('/about')
            .then(response => {
                const images = imageUtils.getAttFromScript(response.data.htmlScript);
                this.setState({
                    ...this.state,
                    isLoaded: true,
                    htmlScript: response.data.htmlScript,
                    currentImages: images,
                    oldImages: images
                });
            });
    }

    updatePage = () => {
        const content = this.state.htmlScript;
        axios.put('/about', { id: 1, htmlScript: content })
            .then(response => {
                console.log('Updated successfully')
                console.log('Deleting images on firebase');
                imageUtils.removeUnusedImages(this.state.currentImages, imageUtils.getAttFromScript(content));
                const images = imageUtils.getAttFromScript(response.data.htmlScript);
                this.setState({
                    ...this.state,
                    htmlScript: response.data.htmlScript,
                    currentImages: images,
                    oldImages: images,
                })
            }, err => {
                console.log('Error')
            })
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true });
    }

    onUploadImages = (imgURL) => {
        this.setState({ ...this.state, currentImages: [...this.state.currentImages, imgURL] });
    }

    editorOnChange = (content) => {
        this.setState({ ...this.state, htmlScript: content });
    }

    cancelUpdate = () => {
        imageUtils.removeUnusedImages(this.state.currentImages, this.state.oldImages);
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="container text-center" style={{ padding: '20px' }}>
                <div className="row">
                    <div className="col text-center">
                        <h3>About page</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {
                            !this.state.editorIsLoaded &&
                            <div className="text-center">
                                <Spinner />
                            </div>
                        }
                        {this.state.isLoaded &&
                            <TextEditor
                                initText={this.state.htmlScript}
                                onUploadImages={this.onUploadImages}
                                setLoaded={this.setLoaded}
                                height='800px'
                                onChange={this.editorOnChange}
                            />
                        }
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <button onClick={this.cancelUpdate} className="btn btn-danger m-2">Hủy</button>
                        <button type="button" onClick={this.updatePage} className="btn btn-success m-2">Lưu</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(AboutEditPage);