import React from 'react';
import './CarouselBanner.css';
const Banner = (props) => {
    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-touch="true">
            <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
                <div className="overlay"></div>
                <div className="carousel-item active">
                    <img className="d-block w-100 banner-img" src="https://static.lunglinh.vn/images/Rem-cua-Lung-Linh.jpg" alt="First slide" />
                    <div className="carousel-caption d-md-block">
                        <h1 className="animated-fadeInDown carousel-title">Perfect Synergy of Classic & Modern.</h1>
                        <p className="animated-fadeInUp carousel-body">LungLinh đem đến sự kết hợp hoàn hảo giữa nét cổ điển và hiện đại cho ngôi nhà của bạn.</p>
                        <button type="button" className="btn btn-warning animated-fadeInUp">Learn more</button>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="d-block w-100 banner-img" src="https://static.lunglinh.vn/images/Man-cua-Lung-Linh.jpg" alt="Second slide" />
                    <div className="carousel-caption d-md-block">
                        <h1 className="animated-fadeInDown carousel-title">Best Services and Commitments</h1>
                        <p className="animated-slideInRight carousel-body">LungLinh cam kết đem đến chất lượng dịch vụ và sản phẩm tốt nhất cho khách hàng.</p>
                        <button type="button" className="btn btn-warning animated-slideInLeft">Learn more</button>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="d-block w-100 banner-img" src="https://static.lunglinh.vn/images/Rem-cua-dep.jpg" alt="Third slide" />
                    <div className="carousel-caption d-md-block">
                        <h1 className="animated-fadeInDown carousel-title">Quality. Not Quantity</h1>
                        <p className="animated-slideInLeft carousel-body">LungLinh luôn tập trung phát triển chất lượng sản phẩm của mình thay vì số lượng.</p>
                        <button type="button" className="btn btn-warning animated-slideInRight">Learn more</button>
                    </div>
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
}

export default Banner;