const truncateText = (text) => {
    let words = text.split(' ');
    const maxlength = Math.min(words.length, 15)
    words = words.slice(0, maxlength)
    return words.join(' ') + '...'
}

const parseDate = (dateString) => {
    return `Ngày ${dateString.split('-')[2]} tháng ${dateString.split('-')[1]} ${dateString.split('-')[0]}`
}

export const stringUtils = {
    truncateText,
    parseDate
}