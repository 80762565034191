import axios from "../axios";
import { BehaviorSubject } from "rxjs";
import jwtDecode from 'jwt-decode';

const authInfoSubject = new BehaviorSubject(
  JSON.parse(window.localStorage.getItem("authInfo"))
);

const login = (userCredentials) => {
  return axios
    .post("/user/authenticate", userCredentials)
    .then((rs) => {
      window.localStorage.setItem("authInfo", JSON.stringify(rs.data));
      authInfoSubject.next(rs.data);
      return rs;
    }, err => {
      return Promise.reject(err.response.data.details)
    });
};

const logout = () => {
  window.localStorage.removeItem("authInfo");
  authInfoSubject.next(null);
};

const isAuthenticated = () => {
  const authInfo = JSON.parse(window.localStorage.getItem("authInfo"));
  if(authInfo === null) {
    return false;
  }
  const token = jwtDecode(authInfo.token);
  return Date.now()/1000 < token.exp;
}

export const AuthenticationService = {
  isAuthenticated,
  login,
  logout,
  authInfo: authInfoSubject.asObservable(),
  get authInfoValue() {
    return authInfoSubject.value;
  },
};

