import React, { Component } from 'react';
import ProductForm from '../../../Components/Forms/ProductForm';
import axios from '../../../axios';
import { imageUtils } from '../../../Services/imageUtils';
import { withRouter } from 'react-router-dom';
import CategoryForm from '../../../Components/Forms/CategoryForm';
import ProductLineForm from '../../../Components/Forms/ProductLineForm';
import ConfirmModal from '../../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';
class ProductCreatePage extends Component {

    state = {
        isLoaded: false,
        editorIsLoaded: false,
        showConfirmModal: false,
        showCategoryModal: false,
        showProductLineModal: false,
        isSubmitting: false,
        imageFile: null,
        imageFileSmall: null,
        previewImgURL: null,
        subImages: [{ url: null, file: null }, { url: null, file: null }, { url: null, file: null }, { url: null, file: null }],
        categories: [],
        productLines: [],
        currentImages: [],
        newImages: [],
        product: {
            productLineId: '',
            categoryId: '',
            name: '',
            description: '',
            price: '',
            image: '',
            characteristic: '',
            supplier: '',
            unit: '',
            bestSeller: false,
            color: '',
            warranty: '',
            status: true,
            details: '',
            subImage: 0
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        axios.get('/menu')
            .then(rs => {
                this.setState({
                    ...this.state,
                    categories: rs.data,
                    isLoaded: true,
                }, () => {
                    if (this.state.product.categoryId !== '') {
                        const categoryId = this.state.product.categoryId;
                        let productLines = [];
                        this.state.categories.map(category => {
                            if (String(category.id) === categoryId) {
                                productLines = category.productLines;
                            }
                            return category;
                        })
                        this.setState({
                            ...this.state,
                            productLines: productLines
                        })
                    }
                })
            }, err => {
                toast.error(err.response ? err.response.data.error : 'Some error occurs while fetching data');
            })
    }

    handleFormChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;

        if (name === 'status' || name === 'bestSeller') {
            value = e.target.checked
        }
        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                [name]: value
            }
        })
    }

    handleCategoryChange = (e) => {

        let productLines = [];
        const categoryId = e.target.value
        this.state.categories.map(category => {
            if (String(category.id) === categoryId) {
                productLines = category.productLines;
            }
            return category;
        })
        this.setState({
            ...this.state,
            productLines: productLines,
            product: {
                ...this.state.product,
                categoryId: categoryId
            }
        })
    }

    handleImageChange = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        Resizer.imageFileResizer(
            file,
            1980,
            1980,
            'JPEG',
            95,
            0,
            largeFile => {
                reader.readAsDataURL(largeFile)
                reader.onloadend = () => {
                    this.setState({
                        ...this.state,
                        imageFile: largeFile,
                        previewImgURL: reader.result,
                    })
                }
                Resizer.imageFileResizer(
                    file,
                    400,
                    400,
                    'JPEG',
                    95,
                    0,
                    smallFile => {
                        console.log('resize success', smallFile)
                        this.setState({
                            ...this.state,
                            imageFileSmall: smallFile,
                        })
                    },
                    'blob'
                );
            },
            'blob'
        );
    }

    handleSubImageChange = (e, index) => {
        let file = e.target.files[0];
        console.log('fei', file)
        if (file !== undefined) {
            let reader = new FileReader();
            Resizer.imageFileResizer(
                file,
                1980,
                1980,
                'JPEG',
                95,
                0,
                resizedFile => {
                    reader.readAsDataURL(resizedFile)
                    reader.onloadend = () => {
                        const newSubImages = [...this.state.subImages];
                        newSubImages[index] = { url: reader.result, file: resizedFile };
                        this.setState({
                            ...this.state,
                            subImages: newSubImages,
                            product: { ...this.state.product, subImage: Math.max(this.state.product.subImage, index + 1) }
                        });
                    }
                },
                'blob'
            );
        }
    }

    editorOnChange = (data) => {
        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                details: data,
            }
        })
    }


    onUploadImages = (imgURL) => {
        this.setState({ ...this.state, currentImages: [...this.state.currentImages, imgURL] });
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showConfirmModal: false });
    }

    uploadSubImages = (imgName) => {
        for (const [index, image] of this.state.subImages.entries()) {
            if (image.file !== null) {
                const name = imageUtils.getSubImageName(imgName, index);
                imageUtils.uploadImage(image.file, name)
                    .then(() => { console.log(`uploaded sub image ${index}`) }, err => console.log(err.response.data.error))
            }
        }
    }

    handleSubmitModalOnClick = () => {
        this.setState({ ...this.state, isSubmitting: true });
        console.log('Submit product', this.state.product);
        const imageName = imageUtils.getRandomName();
        imageUtils.uploadImage(this.state.imageFileSmall, imageName)
            .then(rs => {
                const imageURL = rs.data;
                imageUtils.uploadImage(this.state.imageFile, imageUtils.getImageLargeSizeName(imageName))
                    .then((rs) => console.log(`Uploaded image at URL ${rs.data}`), err => { toast.error(err.response ? err.response.data.error : 'Some error occurs') })
                this.uploadSubImages(imageName);
                axios.post('/products', { ...this.state.product, image: imageURL })
                    .then(() => {
                        this.props.history.goBack();
                    }, err => {
                        this.setState({ ...this.state, isSubmitting: false })
                        toast.error(err.response ? err.response.data.error : 'Some error occurs');
                    })
            }, err => toast.error(err.response ? err.response.data.error : 'Some error occurs'))
    }


    onSubmit = () => {
        this.setState({ ...this.state, showConfirmModal: true });
    }

    onCancel = () => {
        imageUtils.removeUnusedImages(this.state.currentImages, imageUtils.getAttFromScript(this.state.product.details));
        this.props.history.goBack();
    }

    onAddCategory = () => {
        this.setState({ ...this.state, showCategoryModal: true })
    }

    onAddProductLine = () => {
        this.setState({
            ...this.state,
            showProductLineModal: true,
            isUpdateForm: false,
        })
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true });
    }

    onAddCategorySuccessfully = () => {
        this.setState({ ...this.state, showCategoryModal: false });
        this.fetchData();
        toast.success('Thêm phân loại sản phẩm mới thành công');
    }

    onAddProductLineSuccessfully = () => {
        this.setState({ ...this.state, showProductLineModal: false });
        this.fetchData();
        toast.success('Thêm dòng sản phẩm mới thành công')
    }

    render() {
        return (
            <div className="container mt-3">
                <div className="row">
                    <div className="col text-center">
                        <h3>SẢN PHẨM</h3>
                    </div>
                </div>
                {
                    this.state.isLoaded &&
                    <React.Fragment>
                        <ProductForm
                            product={this.state.product}
                            handleFormChange={this.handleFormChange}
                            handleImageChange={this.handleImageChange}
                            previewImage={this.state.previewImgURL}
                            onSubmit={this.onSubmit}
                            cancelOnChange={this.onCancel}
                            categories={this.state.categories}
                            productLines={this.state.productLines}
                            categoryOnChange={this.handleCategoryChange}
                            editorIsLoaded={this.state.editorIsLoaded}
                            setLoaded={this.setLoaded}
                            editorOnChange={this.editorOnChange}
                            onUploadImages={this.onUploadImages}
                            update={false}
                            isSubmitting={this.state.isSubmitting}
                            onAddCategory={this.onAddCategory}
                            onAddProductLine={this.onAddProductLine}
                            previewSubImages={this.state.subImages}
                            handleSubImageChange={this.handleSubImageChange}
                        />
                        <ConfirmModal
                            title={'Tạo sản phẩm'}
                            message={'Bạn có muốn lưu sản phẩm này?'}
                            show={this.state.showConfirmModal}
                            onClose={this.handleCloseModalOnClick}
                            isLoading={this.state.isSubmitting}
                            onAction={this.handleSubmitModalOnClick}
                        />
                        <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <CategoryForm
                            category={{
                                name: '',
                                description: '',
                                image: '',
                            }}
                            show={this.state.showCategoryModal}
                            isUpdateForm={false}
                            onAddSuccessfully={this.onAddCategorySuccessfully}
                            onClose={() => this.setState({ ...this.state, showCategoryModal: false })}
                        />
                        <ProductLineForm
                            categories={this.state.categories}
                            productLine={{
                                name: '',
                                description: '',
                                categoryId: ''
                            }}
                            show={this.state.showProductLineModal}
                            isUpdateForm={false}
                            onAddSuccessfully={this.onAddProductLineSuccessfully}
                            onClose={() => this.setState({ ...this.state, showProductLineModal: false })}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default withRouter(ProductCreatePage);