import React, { Component } from 'react';
import './Layout.css';
import Backdrop from '../../Backdrop/Backdrop';
import Toolbar from '../../Toolbar/Toolbar';
import SideDrawer from '../../SideDrawer/SideDrawer';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import axios from '../../../axios';
import { withRouter } from 'react-router-dom'
import CopyRightFooter from '../../CopyRightFooter/CopyRightFooter';
import { AuthenticationService } from '../../../Services/AuthenticationService';

class Layout extends Component {

    state = {
        sideDrawerOpen: false,
        navState: 'header',
        isLoaded: false,
        products: [],
        isAdmin: AuthenticationService.isAuthenticated(),
    }

    componentDidMount() {
        AuthenticationService.authInfo.subscribe(() => {
            this.setState({
                ...this.state,
                isAdmin: AuthenticationService.isAuthenticated()
            })
        })

        axios.get('/menu')
            .then(response => {
                this.setState({ ...this.state, isLoaded: true, products: response.data ? response.data : [] });
            });

        window.addEventListener('scroll', this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        const offset = window.pageYOffset;
        if (offset > 180) {
            const newState = { ...this.state, navState: 'header inView' }
            this.setState(newState);
        } else if (offset > 120) {
            const newState = { ...this.state, navState: 'header sticky' };
            this.setState(newState);
        } else {
            const newState = { ...this.state, navState: 'header' }
            this.setState(newState);
        }
    }

    drawerToggleClickHandler = () => {
        if (!this.state.sideDrawerOpen) {
            document.body.classList.add('prevent-scroll');
        } else {
            document.body.classList.remove('prevent-scroll');
        }
        this.setState({
            ...this.state,
            sideDrawerOpen: !this.state.sideDrawerOpen,
        })
    };

    backdropClickHandler = () => {
        this.setState({
            ...this.state,
            sideDrawerOpen: false,
        });
        document.body.classList.remove('prevent-scroll');
        let els = document.querySelectorAll('.dropdown-menu')
        for (let i = 0; i < els.length; i++) {
            els[i].classList.remove('show')
        }
    };

    logout = () => {
        AuthenticationService.logout();
        this.setState({
            ...this.state,
            isAdmin: false,
        })
    }

    render() {
        let backdrop, headers, footer;
        if (this.state.sideDrawerOpen) {
            backdrop = <Backdrop click={this.backdropClickHandler} />
        }
        if (this.props.location.pathname !== '/login') {
            headers =
                <React.Fragment>
                    <Header></Header>
                    <div className="toolbarContainer">
                        <Toolbar
                            navState={this.state.navState}
                            products={this.state.products}
                            isLoaded={this.state.isLoaded}
                            isAdmin={this.state.isAdmin}
                            logout={this.logout}
                            drawerClickHandler={this.drawerToggleClickHandler} />
                    </div>
                    <SideDrawer
                        isLoaded={this.state.isLoaded}
                        products={this.state.products}
                        isAdmin={this.state.isAdmin}
                        close={this.backdropClickHandler}
                        show={this.state.sideDrawerOpen}
                        logout={this.logout} />
                    {backdrop}
                </React.Fragment>
            footer =
                <React.Fragment>
                    <Footer products={this.state.products}></Footer>
                    <CopyRightFooter></CopyRightFooter>
                </React.Fragment>
        }
        return (
            this.state.isLoaded ?
                <React.Fragment>
                    {headers}
                    <main>
                        {this.props.children}
                    </main>
                    {footer}
                </React.Fragment> : null
        );
    }
}

export default withRouter(Layout);