import React, { Component } from 'react';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from 'yup';
import Spinner from '../Spinner';
import { imageUtils } from '../../Services/imageUtils';
import axios from '../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import TextEditor from '../Editor/TextEditor';

class CategoryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            category: props.category,
            isUpdateForm: props.isUpdateForm,
            isUploading: false,
            editorIsLoaded: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.category !== this.props.category) {
            this.setState({
                ...this.state,
                category: this.props.category
            })
        }
    }
    handleImageChange = (e) => {
        if (e.target.value !== '') {
            this.setState({
                ...this.state,
                isUploading: true,
            })
            let file = e.target.files[0];
            const imageName = imageUtils.getRandomName();
            imageUtils.uploadImage(file, imageName)
                .then(rs => {
                    const imageURL = rs.data;
                    this.setState({
                        ...this.state,
                        isUploading: false,
                        category: { ...this.state.category, image: imageURL },
                    })
                }, err => {
                    toast.error(err.response ? err.response.data.error : 'Some error occurs');
                })
        }
    }

    handleOnChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                [name]: value
            }
        })
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true })
    }

    editorOnChange = (content) => {
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                content: content
            }
        });
    }

    render() {
        return (
            this.state.category ?
                <Modal show={this.props.show} backdrop="static" centered onHide={this.props.onClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Phân loại sản phẩm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            enableReinitialize="true"
                            initialValues={{
                                id: this.state.category ? this.state.category.id : '',
                                name: this.state.category ? this.state.category.name : '',
                                description: this.state.category ? this.state.category.description : '',
                                image: this.state.category ? this.state.category.image : '',
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required(
                                    "Bạn cần nhập tên"
                                ),
                            })}
                            onSubmit={({ name }, { setStatus, setSubmitting, setErrors }) => {
                                setStatus();
                                setSubmitting(true)
                                if (this.props.isUpdateForm) {
                                    axios.put('/categories/' + this.state.category.id, { ...this.state.category })
                                        .then(() => {
                                            setSubmitting(false)
                                            this.props.onUpdateSuccessfully();
                                        },
                                            err => {
                                                toast.error(err.response ? err.response.data.error : 'Some error occurs');
                                                setSubmitting(false)
                                            })
                                } else {
                                    axios.post('/categories', { name: this.state.category.name, description: this.state.category.description, image: this.state.category.image, content: this.state.category.content, metaImage: this.state.category.metaImage, metaTitle: this.state.category.metaTitle })
                                        .then(() => {
                                            setSubmitting(false);
                                            this.props.onAddSuccessfully();
                                        }, err => {
                                            toast.error(err.response ? err.response.data.error : 'Some error occurs');
                                            setSubmitting(false)
                                        })
                                }
                            }}>
                            {({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    <div className="form-group text-center">
                                        <label htmlFor="image" style={{ display: 'block' }}>Hình ảnh</label>
                                        {
                                            (this.state.category.image !== '' && !this.state.isUploading) &&
                                            <img src={this.state.category.image} alt="" class="img-thumbnail" style={{ height: '200px' }} />
                                        }
                                        {
                                            this.state.isUploading && <Spinner />
                                        }
                                        <Field onChange={this.handleImageChange} name="img" type="file" className={"form-control-file" + (errors.img && touched.img ? " is-invalid" : "")} />
                                        <ErrorMessage name="img" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Tên</label>
                                        <Field name="name" type="text" value={this.state.category.name} onChange={this.handleOnChange} className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="description">Mô tả</label>
                                        <Field name="description" type="text" value={this.state.category.description} onChange={this.handleOnChange} className={"form-control" + (errors.description && touched.description ? " is-invalid" : "")} />
                                        <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="metaTitle">Tiêu đề</label>
                                        <Field name="metaTitle" type="text" value={this.state.category.metaTitle} onChange={this.handleOnChange} className={"form-control" + (errors.metaTitle && touched.metaTitle ? " is-invalid" : "")} />
                                        <ErrorMessage name="metaTitle" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="metaImage">Link ảnh meta</label>
                                        <Field name="metaImage" type="text" value={this.state.category.metaImage} onChange={this.handleOnChange} className={"form-control" + (errors.metaImage && touched.metaImage ? " is-invalid" : "")} />
                                        <ErrorMessage name="metaImage" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group text-center">
                                        <button type="button" className="btn btn-danger m-2" data-dismiss="modal" onClick={this.props.onClose}>Hủy</button>
                                        <button type="submit" className="btn btn-primary m-2" disabled={isSubmitting}>Lưu</button>
                                        {isSubmitting && (
                                            <Spinner />
                                        )}
                                    </div>
                                    {status && (
                                        <div className={"alert alert-danger"}>{status}</div>
                                    )}
                                    <label for="content">Nội dung</label>
                                    {
                                        !this.state.editorIsLoaded &&
                                        <div className="text-center">
                                            <Spinner />
                                        </div>
                                    }
                                    <TextEditor
                                        initText={this.state.category.content}
                                        setLoaded={this.setLoaded}
                                        onUploadImages={this.onUploadImages}
                                        onChange={this.editorOnChange}
                                        id="content"
                                        height="700px"
                                    />
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={3000}
                                        hideProgressBar={false}
                                        newestOnTop
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal> : null
        );
    }
}

export default CategoryForm;