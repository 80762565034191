import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import Pagination from '../../../Components/Pagination';
import ConfirmModal from '../../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import { stringUtils } from '../../../Services/stringUtils';

class ProductsPage extends Component {

    state = {
        isLoaded: false,
        isLoading: false,
        target: null,
        showModal: false,
        modalMessage: '',
        categoryIsLoaded: false,
        categories: [],
        productLines: [],
        productLinesDisable: true,
        products: null,
        page: {
            size: 10,
            pageNumber: 0,
            totalPages: 1,
            sort: 'DESC',
            sortBy: 'id',
            category: null,
            productLine: null,
        }
    }
    componentDidMount() {
        // TODO: productPage & CreatePage use the same data, so it is better to apply redux to this.
        axios.get('/menu')
            .then(rs => {
                this.setState({
                    ...this.state,
                    categories: rs.data,
                    categoryIsLoaded: true
                })
                this.fetchData();
            },
                err => {
                    toast.error(err.response ? err.response.data.error : 'Some error occurs');
                })
    }

    // TODO: refactor code later
    handleSortOnChange = (e) => {
        const sortIndex = e.target.value;
        if (sortIndex === '1') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'name',
                    sort: 'ASC',
                }
            }, () => this.fetchData());
        } else if (sortIndex === '2') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'name',
                    sort: 'DESC',
                }
            }, () => this.fetchData());
        } else if (sortIndex === '3') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'createdDate',
                    sort: 'DESC',
                }
            }, () => this.fetchData());
        } else if (sortIndex === '4') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'createdDate',
                    sort: 'ASC',
                }
            }, () => this.fetchData());
        }
    }

    handleCategoryOnChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            this.setState({
                ...this.state,
                productLinesDisable: true,
                page: {
                    ...this.state.page,
                    category: null,
                    pageNumber: 0,
                    productLine: null
                }
            }, () => this.fetchData())
        } else {
            this.setState({
                ...this.state,
                productLinesDisable: false,
                productLines: this.getProductLineOptions(value),
                page: {
                    ...this.state.page,
                    category: value,
                    pageNumber: 0,
                    productLine: null
                }
            }, () => this.fetchData())
        }
    }

    handleProductLineOnChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    pageNumber: 0,
                    productLine: null
                }
            }, () => this.fetchData())
        } else {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    pageNumber: 0,
                    productLine: value
                }
            }, () => this.fetchData())
        }
    }
    getProductLineOptions = (categoryId) => {
        let productLines;
        this.state.categories.map(category => {
            if (category.uri === categoryId) {
                productLines = category.productLines;
            }
            return category;
        })
        return productLines;
    }

    renderCategoryOptions = () => {
        return this.state.categories.map(category => {
            return (
                <option key={category.id} value={category.uri}>{category.name}</option>
            );
        });
    }

    handleDeleteOnClick = (id) => {
        this.setState({ ...this.state, target: id, modalMessage: 'Bạn có muốn xóa sản phẩm số ' + id, showModal: true });
    }


    renderProductLineOptions = () => {
        return this.state.productLines.map(productLine => {
            return (
                <option key={productLine.id} value={productLine.uri}>{productLine.name}</option>
            );
        })
    }

    fetchData = () => {
        axios.get('/products', {
            params: {
                page: this.state.page.pageNumber,
                size: this.state.page.size,
                sort: this.state.page.sort,
                sortBy: this.state.page.sortBy,
                category: this.state.page.category,
                productLine: this.state.page.productLine
            }
        }).then(rs => {
            this.setState({
                ...this.state,
                products: rs.data.content,
                isLoading: false,
                isLoaded: true,
                page: {
                    ...this.state.page,
                    totalPages: rs.data.totalPages,
                    pageNumber: rs.data.number
                }
            });
            this.scrollTop();
        }, err => {
            toast.error(err.response ? err.response.data.error : 'Some error occurs');
            this.setState({
                ...this.state,
                isLoading: false,
            })
        })
    }

    handlePageChange = (page) => {
        this.setState({
            ...this.state,
            isLoading: true,
            page: { ...this.state.page, pageNumber: page.selected }
        }, () => this.fetchData());
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showModal: false });
    }

    handleSubmitModalOnClick = () => {
        const id = this.state.target;
        this.setState({ ...this.state, isLoading: true }, () => {
            axios.delete('/products/' + id)
                .then(rs => {
                    this.setState({ ...this.state, showModal: false, isLoading: false })
                    this.fetchData();
                    toast.warn('Đã xóa sản phẩm số : ' + id);

                }, err => {
                    toast.error(err.response ? err.response.data.error : 'Some error occurs');
                    this.setState({ ...this.state, isLoading: false })
                })
        });
    }
    renderTable = () => {
        return (
            <tbody>
                {
                    this.state.products.map(product => {
                        return (
                            <tr key={product.id}>
                                <th scope="row">{product.id}</th>
                                <td>{product.name}</td>
                                <td>{stringUtils.truncateText(product.description)}</td>
                                <td>{product.price}</td>
                                <td>{product.createdDate}</td>
                                <td>
                                    <Link to={'/admin/products/' + product.id}>
                                        <button className="btn btn-sm btn-info ml-2 mb-1" style={{ width: '50px' }}>Xem</button>
                                    </Link>
                                    <button onClick={() => this.handleDeleteOnClick(product.id)} className="btn btn-sm btn-danger ml-2 mb-1" style={{ width: '50px' }}>Xóa</button>
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }

    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    render() {
        return (
            <div className="container mt-3">
                <div className="row">
                    <div className="col text-center py-2">
                        <h2>SẢN PHẨM</h2>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-2 py-2">
                        <Link to="/admin/products/create">
                            <button className="btn btn-sm btn-primary">Thêm</button>
                        </Link>
                    </div>
                    <div className="col-xs-8">
                        <form className="form-inline">
                            <div className="input-group input-group-sm mb-3 m-2">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" for="sortGroup">Phân loại</label>
                                </div>
                                <select className="custom-select" id="sortGroup" onChange={this.handleCategoryOnChange}>
                                    <option value="">Tất cả</option>
                                    {
                                        this.state.categoryIsLoaded ? this.renderCategoryOptions() : null
                                    }
                                </select>
                            </div>
                            <div className="input-group input-group-sm mb-3 m-2">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" for="sortGroup">Chi tiết</label>
                                </div>
                                <select disabled={this.state.productLinesDisable} className="custom-select" id="sortGroup" onChange={this.handleProductLineOnChange}>
                                    <option value="">Tất cả</option>
                                    {
                                        this.state.productLinesDisable ? null : this.renderProductLineOptions()
                                    }
                                </select>
                            </div>
                            <div className="input-group input-group-sm mb-3 m-2">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" for="sortGroup">Sắp xếp theo</label>
                                </div>
                                <select className="custom-select" id="sortGroup" onChange={this.handleSortOnChange}>
                                    <option value="1">A-Z</option>
                                    <option value="2">Z-A</option>
                                    <option value="3">Mới nhất</option>
                                    <option value="4">Cũ nhất</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col table-responsive">
                        <table className="table table-hover table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tên</th>
                                    <th scope="col" style={{width: '50%'}}>Mô tả</th>
                                    <th scope="col">Giá</th>
                                    <th scope="col">Ngày</th>
                                    <th scope="col">Tác vụ</th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoaded ? this.renderTable() : null
                            }
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Pagination pageCount={this.state.page.totalPages} handlePageChange={this.handlePageChange} />
                    </div>
                </div>
                <ConfirmModal
                    title={'Xóa bài viết'}
                    message={this.state.modalMessage}
                    show={this.state.showModal}
                    onClose={this.handleCloseModalOnClick}
                    isLoading={this.state.isLoading}
                    onAction={this.handleSubmitModalOnClick}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }
}

export default ProductsPage;