import React, { Component } from 'react';
import axios from '../../axios';
import TextEditor from '../../Components/Editor/TextEditor';
import ConfirmModal from '../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../../Components/Spinner';

class ServicesEditPage extends Component {
    state = {
        editorIsLoaded: false,
        content: null,
        isLoaded: false,
        showModal: false,
        isSubmitting: false,
    }

    componentDidMount() {
        axios.get('/services/1')
            .then(rs => {
                this.setState({
                    ...this.state,
                    isLoaded: true,
                    content: rs.data.content
                })
            })
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true });
    }

    cancelUpdate = () => {
        this.props.history.goBack();
    }

    updatePage = () => {
        this.setState({ ...this.state, showModal: true })
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showModal: false });
    }

    handleSubmitModalOnClick = () => {
        this.setState({ ...this.state, isSubmitting: true })
        axios.put('/services/1', { content: this.state.content })
            .then(() => {
                this.setState({ ...this.state, isSubmitting: false, showModal: false })
                toast.success('Cập nhật trang thành công.')
            }, err => {
                this.setState({ ...this.state, isSubmitting: false, showModal: false })
                toast.error(err.response ? err.response.data.error : 'Some error occurs')
            })
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <h3>DỊCH VỤ</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {
                            !this.state.editorIsLoaded &&
                            <div className="text-center">
                                <Spinner />
                            </div>
                        }
                        {
                            this.state.isLoaded &&
                            <TextEditor
                                initText={this.state.content}
                                // onUploadImages={this.onUploadImages}
                                setLoaded={this.setLoaded}
                                height='800px'
                                onChange={(content) => { this.setState({ ...this.state, content: content }) }}
                            />
                        }
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <button onClick={this.cancelUpdate} className="btn btn-danger m-2">Hủy</button>
                        <button type="button" onClick={this.updatePage} className="btn btn-success m-2">Lưu</button>
                    </div>
                </div>
                <ConfirmModal
                    title={'Dịch Vụ'}
                    message={'Bạn có muốn lưu trang này?'}
                    show={this.state.showModal}
                    onClose={this.handleCloseModalOnClick}
                    isLoading={this.state.isSubmitting}
                    onAction={this.handleSubmitModalOnClick}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }
}
export default ServicesEditPage;