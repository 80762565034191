import React, { Component } from 'react';
import ProductForm from '../../../Components/Forms/ProductForm';
import axios from '../../../axios';
import { imageUtils } from '../../../Services/imageUtils';
import { withRouter } from 'react-router-dom';
import ConfirmModal from '../../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';

class ProductUpdatePage extends Component {

    state = {
        isLoaded: false,
        editorIsLoaded: false,
        imageFile: 'product-img',
        imageFileSmall: null,
        showModal: false,
        previewImgURL: null,
        previewName: null,
        categories: [],
        productLines: [],
        subImages: [{ url: null, file: null }, { url: null, file: null }, { url: null, file: null }, { url: null, file: null }],
        isSubmitting: false,
        product: {
            id: this.props.match.params.id,
            productLineName: '',
            categoryName: '',
            name: '',
            description: '',
            price: '',
            image: '',
            characteristic: '',
            supplier: '',
            unit: '',
            bestSeller: false,
            color: '',
            warranty: '',
            status: true,
            details: '',
            subImage: 0
        }
    }
    apiURL = '/products/' + this.props.match.params.id;

    componentDidMount() {
        axios.get(`/products/id/${this.props.match.params.id}`)
            .then(rs => {
                let subImageList = this.state.subImages;
                subImageList = imageUtils.getSubImages(rs.data.image, rs.data.subImage);
                this.setState({
                    ...this.state,
                    product: {
                        ...rs.data,
                        image: '',
                    },
                    subImages: subImageList,
                    previewImgURL: rs.data.image,
                    previewName: rs.data.image,
                    isLoaded: true,
                })
            })
    }

    handleFormChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;

        if (name === 'status' || name === 'bestSeller') {
            value = e.target.checked
        }
        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                [name]: value
            }
        })
    }

    handleCategoryChange = (e) => {

        let productLines;
        const categoryId = e.target.value
        this.state.categories.map(category => {
            if (String(category.id) === categoryId) {
                productLines = category.productLines;
            }
            return category;
        })
        this.setState({
            ...this.state,
            productLines: productLines,
            product: {
                ...this.state.product,
                categoryId: categoryId
            }
        })
    }

    handleImageChange = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        const fileName = String(e.target.value);

        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                image: fileName
            }
        }, () => {
            Resizer.imageFileResizer(
                file,
                1980,
                1980,
                'JPEG',
                95,
                0,
                largeFile => {
                    reader.readAsDataURL(largeFile)
                    reader.onloadend = () => {
                        this.setState({
                            ...this.state,
                            imageFile: largeFile,
                            previewImgURL: reader.result,
                        })
                    }
                    Resizer.imageFileResizer(
                        file,
                        400,
                        400,
                        'JPEG',
                        95,
                        0,
                        smallFile => {
                            reader.readAsDataURL(smallFile)
                            reader.onloadend = () => {
                                this.setState({
                                    ...this.state,
                                    imageFileSmall: smallFile,
                                })
                            }
                        },
                        'blob'
                    );
                },
                'blob'
            );
        })
    }
    editorOnChange = (data) => {
        this.setState({
            ...this.state,
            product: {
                ...this.state.product,
                details: data,
            }
        })
    }

    onSubmit = () => {
        this.setState({ ...this.state, showModal: true });
    }

    onCancel = () => {
        this.props.history.goBack();
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true });
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showModal: false });
    }

    handleSubmitModalOnClick = () => {
        this.setState({ ...this.state, isSubmitting: true });
        const imageName = imageUtils.getImageNameFromURL(this.state.previewName);
        this.uploadSubImages(imageName);
        if (this.state.product.image !== '') {
            imageUtils.uploadImage(this.state.imageFileSmall, imageName)
                .then((rs) => {
                    console.log(`Uploaded image at URL ${rs.data}`)
                    const imageURL = rs.data;
                    imageUtils.uploadImage(this.state.imageFile, imageUtils.getImageLargeSizeName(imageName))
                        .then(() => { console.log('uploaded large size image') })
                    axios.put(this.apiURL, { ...this.state.product, image: imageURL })
                        .then(() => {
                            this.setState({ ...this.state, isSubmitting: false, showModal: false });
                            toast.success('Cập nhật sản phẩm thành công');
                        }, err => {
                            this.setState({ ...this.state, isSubmitting: false })
                            toast.error(err.response ? err.response.data.error : 'Some error occurs');
                        })
                })

        } else {
            axios.put(this.apiURL, { ...this.state.product, image: this.state.previewImgURL })
                .then(() => {
                    this.setState({ ...this.state, isSubmitting: false, showModal: false })
                    toast.success('Cập nhật sản phẩm thành công');
                }, err => {
                    this.setState({ ...this.state, isSubmitting: false })
                    toast.error(err.response ? err.response.data.error : 'Some error occurs');
                })
        }
    }

    handleSubImageChange = (e, index) => {
        let file = e.target.files[0];
        console.log('fei', file)
        if (file !== undefined) {
            let reader = new FileReader();
            Resizer.imageFileResizer(
                file,
                1980,
                1980,
                'JPEG',
                95,
                0,
                resizedFile => {
                    reader.readAsDataURL(resizedFile)
                    reader.onloadend = () => {
                        const newSubImages = [...this.state.subImages];
                        newSubImages[index] = { url: reader.result, file: resizedFile };
                        this.setState({
                            ...this.state,
                            subImages: newSubImages,
                            product: { ...this.state.product, subImage: Math.max(this.state.product.subImage, index + 1) }
                        }, () => console.log('test', this.state.subImages));
                    }
                },
                'blob'
            );
        }
    }

    uploadSubImages = (imgName) => {
        for (const [index, image] of this.state.subImages.entries()) {
            if (image.file !== null) {
                const name = imageUtils.getSubImageName(imgName, index);
                imageUtils.uploadImage(image.file, name)
                    .then(() => { }, err => { toast.error(err.response ? err.response.data : 'Some errors occur') }, () => { console.log(`uploaded sub image ${index}`) })
            }
        }
    }

    render() {
        return (
            <div className="container mt-3">
                <div className="row">
                    <div className="col text-center">
                        <h3>SẢN PHẨM</h3>
                    </div>
                </div>
                {
                    this.state.isLoaded &&
                    <ProductForm
                        product={this.state.product}
                        handleFormChange={this.handleFormChange}
                        handleImageChange={this.handleImageChange}
                        previewImage={this.state.previewImgURL}
                        onSubmit={this.onSubmit}
                        cancelOnChange={this.onCancel}
                        categories={this.state.categories}
                        productLines={this.state.productLines}
                        categoryOnChange={this.handleCategoryChange}
                        editorIsLoaded={this.state.editorIsLoaded}
                        setLoaded={this.setLoaded}
                        editorOnChange={this.editorOnChange}
                        update={true}
                        isSubmitting={this.state.isSubmitting}
                        previewSubImages={this.state.subImages}
                        handleSubImageChange={this.handleSubImageChange}
                    />
                }
                <ConfirmModal
                    title={'Tạo sản phẩm'}
                    message={'Bạn có muốn lưu sản phẩm này?'}
                    show={this.state.showModal}
                    onClose={this.handleCloseModalOnClick}
                    isLoading={this.state.isSubmitting}
                    onAction={this.handleSubmitModalOnClick}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }
}

export default withRouter(ProductUpdatePage);