import axios from 'axios';
import { AuthenticationService } from './Services/AuthenticationService';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
});
instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['Accept'] = 'application/json'
instance.interceptors.request.use(request => {
    request.headers.common['Authorization'] = AuthenticationService.authInfoValue ? 'Bearer ' + AuthenticationService.authInfoValue.token: null;
    return request;
}, error => {   
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 401 || error.response.status === 403) {
        window.location.replace('/admin/login')
    }
    return Promise.reject(error);
})
// instance.interceptors.request...

export default instance;