import React, { Component } from 'react';
import axios from '../../axios';
import Pagination from '../../Components/Pagination';
import ConfirmModal from '../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import CategoryForm from '../../Components/Forms/CategoryForm';
import { stringUtils } from '../../Services/stringUtils';

class CategoriesPage extends Component {
    state = {
        isLoaded: false,
        isLoading: false,
        showConfirmModal: false,
        isUpdateForm: false,
        target: null,
        modalMessage: '',
        categories: [],
        category: null,
        page: {
            size: 10,
            pageNumber: 0,
            totalPages: 1,
            sort: 'ASC',
            sortBy: 'name',
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    renderTable = () => {
        return (
            <tbody>
                {
                    this.state.categories.map(category => {
                        return (
                            <tr key={category.id}>
                                <th scope="row">{category.id}</th>
                                <td>{category.name}</td>
                                <td>{stringUtils.truncateText(category.description)}</td>
                                <td>
                                    <button className="btn btn-sm btn-info ml-2 mb-1" style={{ width: '50px' }} onClick={() => this.showCategoryModal(category)}>Xem</button>
                                    <button onClick={() => this.handleDeleteOnClick(category.id)} className="btn btn-sm btn-danger ml-2 mb-1" style={{ width: '50px' }}>Xóa</button>
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }

    handleDeleteOnClick = (id) => {
        this.setState({ ...this.state, target: id, modalMessage: 'Bạn có muốn xóa sản phẩm số ' + id, showConfirmModal: true });
    }
    onUpdateCategory = (category) => {
        this.setState({ ...this.state, category: category });
    }
    fetchData = () => {
        axios.get('/categories', {
            params: {
                page: this.state.page.pageNumber,
                size: this.state.page.size,
                sort: this.state.page.sort,
                sortBy: this.state.page.sortBy,
            }
        })
            .then(rs => {
                this.setState({
                    ...this.state,
                    categories: rs.data.content,
                    isLoaded: true,
                    page: {
                        ...this.state.page,
                        totalPages: rs.data.totalPages,
                        pageNumber: rs.data.number
                    }
                });
                this.scrollTop();
            })
    }

    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    handleSortOnChange = (e) => {
        const sortIndex = e.target.value;
        if (sortIndex === '1') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'name',
                    sort: 'ASC',
                }
            }, () => this.fetchData());
        } else if (sortIndex === '2') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'name',
                    sort: 'DESC',
                }
            }, () => this.fetchData());
        } else if (sortIndex === '3') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'id',
                    sort: 'DESC',
                }
            }, () => this.fetchData());
        } else if (sortIndex === '4') {
            this.setState({
                ...this.state,
                page: {
                    ...this.state.page,
                    sortBy: 'id',
                    sort: 'ASC',
                }
            }, () => this.fetchData());
        }
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showConfirmModal: false });
    }

    handleSubmitModalOnClick = () => {
        const id = this.state.target;
        this.setState({ ...this.state, isLoading: true }, () => {
            axios.delete('/categories/' + id)
                .then(() => {
                    this.setState({ ...this.state, showConfirmModal: false, isLoading: false })
                    this.fetchData();
                    toast.warn('Đã xóa loại sản phẩm số : ' + id);

                }, err => {
                    toast.error((err.response ? err.response.data.error : 'Some error occurs'));
                    this.setState({ ...this.state, isLoading: false })
                })
        });
    }

    handlePageChange = (page) => {
        this.setState({
            ...this.state,
            isLoading: true,
            page: { ...this.state.page, pageNumber: page.selected }
        }, () => this.fetchData());
    }

    showCategoryModal = (category) => {
        this.setState({ ...this.state, category: category, showCategoryModal: true, isUpdateForm: true })
    }

    onUpdateSuccessfully = () => {
        this.setState({ ...this.state, showCategoryModal: false }, () => this.fetchData())
        toast.success('Cập nhật thành công')
    }
    onAddSuccessfully = () => {
        this.setState({ ...this.state, showCategoryModal: false }, () => this.fetchData())
        toast.success('Thêm loại sản phẩm mới thành công');
    }
    onAddCategory = () => {
        this.setState({
            ...this.state,
            showCategoryModal: true,
            isUpdateForm: false,
            category: { id: null, name: '', description: '', image: '' }
        })
    }

    render() {
        return (
            <div className="container mt-3">
                <div className="row">
                    <div className="col text-center py-2">
                        <h2>LOẠI SẢN PHẨM</h2>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-2 py-2">
                        <button className="btn btn-sm btn-primary" onClick={this.onAddCategory}>Thêm</button>
                    </div>
                    <div className="col-xs-8">
                        <form className="form-inline">
                            <div className="input-group input-group-sm mb-3 m-2">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" htmlFor="sortGroup">Sắp xếp theo</label>
                                </div>
                                <select className="custom-select" id="sortGroup" onChange={this.handleSortOnChange}>
                                    <option value="1">A-Z</option>
                                    <option value="2">Z-A</option>
                                    <option value="3">Mới nhất</option>
                                    <option value="4">Cũ nhất</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col table-responsive">
                        <table className="table table-hover table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tên</th>
                                    <th scope="col">Mô tả</th>
                                    <th scope="col">Tác vụ</th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoaded ? this.renderTable() : null
                            }
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Pagination pageCount={this.state.page.totalPages} handlePageChange={this.handlePageChange} />
                    </div>
                </div>
                <ConfirmModal
                    title={'Xóa bài viết'}
                    message={this.state.modalMessage}
                    show={this.state.showConfirmModal}
                    onClose={this.handleCloseModalOnClick}
                    isLoading={this.state.isLoading}
                    onAction={this.handleSubmitModalOnClick}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <CategoryForm
                    show={this.state.showCategoryModal}
                    category={this.state.category}
                    isUpdateForm={this.state.isUpdateForm}
                    onUpdateSuccessfully={this.onUpdateSuccessfully}
                    onAddSuccessfully={this.onAddSuccessfully}
                    onClose={() => this.setState({ ...this.state, showCategoryModal: false })}
                />
            </div>
        );
    }
}

export default CategoriesPage;