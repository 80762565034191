import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthenticationService } from '../../../Services/AuthenticationService';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const authInfo = AuthenticationService.authInfoValue;
        if (!authInfo || !AuthenticationService.isAuthenticated()) {
            // not logged in so redirect to login page with the return url
            return <Redirect to="/login" />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;