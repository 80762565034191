import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';

const Footer = (props) => {
    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 footer-wrapper">
                        <div className="nav-item-wrapper">
                            <img className="logo footer-logo"
                                alt="logo"
                                src="/images/logo-red-black.png">
                            </img>
                        </div>
                        <div className="nav-item-wrapper">
                            <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                        </div>
                    </div>
                    <div className="col-md-2 footer-wrapper">
                        <div className="nav-item-wrapper title">
                            <h5>DANH MỤC</h5>
                        </div>
                        <div className="nav-item-wrapper">
                            <ul className="bullet">
                                <li>
                                    <NavLink className="footer-nav" to={"/"} exact={true}>Trang chủ</NavLink>
                                </li>
                                <li>
                                    <NavLink className="footer-nav" to={"/products"}>Sản phẩm</NavLink>
                                </li>
                                <li>
                                    <NavLink className="footer-nav" to={"/services"} >Dịch vụ</NavLink>
                                </li>
                                <li>
                                    <NavLink className="footer-nav" to={"/news"} >Tin tức</NavLink>
                                </li>
                                <li>
                                    <NavLink className="footer-nav" to={"/contact"} >Liên hệ</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2 footer-wrapper">
                        <div className="nav-item-wrapper title">
                            <h5>SẢN PHẨM</h5>
                        </div>
                        <div className="nav-item-wrapper">
                            <ul className="bullet">
                                {
                                    props.products.map(category => {
                                        return (
                                            <li key={category.id}>
                                                <NavLink to={{ pathname: '/products', search: `?categoryId=${category.id}` }} className="footer-nav">{category.name}</NavLink>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 footer-wrapper contact-group">
                        <div className="nav-item-wrapper title">
                            <h5>LIÊN HỆ</h5>
                        </div>
                        <div className="nav-item-wrapper">
                            <p><i className="fa fa-map-marker footer-icon"></i>1109 Bùi Văn Hòa, Long Bình, TP. Biên Hòa Đồng Nai.</p>
                            <p><i className="fa fa-phone footer-icon"></i>0987 289 463</p>
                            <p><i className="fa fa-envelope footer-icon"></i>tminhduc2811@gmail.com</p>
                            <div className="icon-circle-group">
                                <a href="/#"><i className="fa fa-facebook soc-icon"></i></a>
                                <a href="/#"><i className="fa fa-instagram soc-icon"></i></a>
                                <a href="/#"><i className="fa fa-linkedin soc-icon"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;