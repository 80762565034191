import React from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from "yup";
import TextEditor from '../Editor/TextEditor';
import Spinner from '../Spinner';

const ProductForm = (props) => {

    return (
        <Formik
            enableReinitialize={true}
            initialValues={
                {
                    productLineId: props.product.productLineId,
                    name: props.product.name,
                    description: props.product.description,
                    price: props.product.price,
                    image: props.product.image,
                    characteristic: props.product.characteristic,
                    supplier: props.product.supplier,
                    unit: props.product.unit,
                    bestSeller: props.product.bestSeller,
                    color: props.product.color,
                    warranty: props.product.warranty,
                    status: props.product.status,
                    categoryId: props.product.categoryId
                }
            }
            validationSchema={
                Yup.object().shape({
                    productLineId: Yup.string().required('Bạn phải chọn dòng sản phẩm'),
                    categoryId: Yup.string().required('Bạn phải chọn loại sản phẩm'),
                    name: Yup.string().required('Tên sản phẩm không được để trống'),
                    description: Yup.string().required('Mô tả phẩm không được để trống'),
                })
            }
            onSubmit={({ productLineId, name, description, price, image, characteristic, supplier, unit, bestSeller, color, warranty, status }, { setStatus, setSubmitting, setErrors }) => {
                props.onSubmit({
                    productLineId: productLineId,
                    name: name,
                    description: description,
                    price: price,
                    image: image,
                    characteristic: characteristic,
                    supplier: supplier,
                    unit: unit,
                    bestSeller: bestSeller,
                    color: color,
                    warranty: warranty,
                    status: status
                })
            }} >
            {({ errors, status, touched, isSubmitting }) => (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="image">Hình ảnh sản phẩm</label>
                                {
                                    (props.previewImage !== null) &&
                                    <img src={props.previewImage} alt="img" className="img-thumbnail" />
                                }
                                <Field disabled={props.isSubmitting} onChange={props.handleImageChange} value={props.product.image} name="image" type="file" className={"form-control-file" + (errors.image && touched.image ? " is-invalid" : "")} />
                                <ErrorMessage name="image" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sub-image-2">Hình ảnh phụ 2</label>
                                {
                                    (props.previewSubImages[0].url !== null) &&
                                    <img src={props.previewSubImages[0].url} alt="sub-img" className="img-thumbnail" />
                                }
                                <Field disabled={props.isSubmitting} onChange={(e) => props.handleSubImageChange(e, 0)} name="sub-image-2" type="file" className="form-control-file" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sub-image-3">Hình ảnh phụ 3</label>
                                {
                                    (props.previewSubImages[1].url !== null) &&
                                    <img src={props.previewSubImages[1].url} alt="sub-img" className="img-thumbnail" />
                                }
                                <Field disabled={props.isSubmitting} onChange={(e) => props.handleSubImageChange(e, 1)} name="sub-image-3" type="file" className="form-control-file" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sub-image-4">Hình ảnh phụ 4</label>
                                {
                                    (props.previewSubImages[2].url !== null) &&
                                    <img src={props.previewSubImages[2].url} alt="sub-img" className="img-thumbnail" />
                                }
                                <Field disabled={props.isSubmitting} onChange={(e) => props.handleSubImageChange(e, 2)} name="sub-image-4" type="file" className="form-control-file" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Form id="product-form">
                                <div className="form-inline">
                                    <div className="form-group mt-2 mb-2">
                                        <label htmlFor="categoryId" className="mr-sm-2">Phân loại</label>
                                        {
                                            props.update ?
                                                <Field value={props.product.categoryId} name="categoryId" as="select" disabled={true} className="form-control custom-select mr-sm-2">
                                                    {
                                                        <option value={props.categoryId} key={props.product.categoryId}>{props.product.categoryName}</option>
                                                    }
                                                </Field> :
                                                <Field disabled={props.isSubmitting} onChange={props.categoryOnChange} value={props.product.categoryId} name="categoryId" as="select" className={"form-control custom-select mr-sm-2" + (errors.categoryId && touched.categoryId ? " is-invalid" : "")}>
                                                    <option value="">Chọn</option>
                                                    {
                                                        props.categories.map(category => {
                                                            return <option value={category.id} key={category.id}>{category.name}</option>
                                                        })
                                                    }
                                                </Field>
                                        }
                                        {
                                            !props.update && <button className="btn btn-info btn-sm mt-1" type="button" onClick={props.onAddCategory}>Thêm</button>
                                        }
                                        <ErrorMessage name="categoryId" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="form-inline mt-2 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="productLineId" className="mr-sm-2">Phân loại</label>
                                        {
                                            props.update ?
                                                <Field name="productLineId" disabled={true} as="select" className="form-control custom-select mr-sm-2">
                                                    <option value={props.product.productLineId} key={props.product.productLineId}>{props.product.productLineName}</option>
                                                </Field> :
                                                <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.productLineId} name="productLineId" as="select" className={"form-control custom-select mr-sm-2" + (errors.productLineId && touched.productLineId ? " is-invalid" : "")}>
                                                    <option value="">Chọn</option>
                                                    {
                                                        props.productLines.map(productLine => {
                                                            return <option value={productLine.id} key={productLine.id}>{productLine.name}</option>
                                                        })
                                                    }
                                                </Field>

                                        }
                                        {
                                            !props.update && <button className="btn btn-info btn-sm mt-1" type="button" onClick={props.onAddProductLine}>Thêm</button>
                                        }
                                        <ErrorMessage name="productLineId" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Tên sản phẩm</label>
                                    <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.name} name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Mô tả sản phẩm</label>
                                    <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.description} name="description" as="textarea" className={"form-control" + (errors.description && touched.description ? " is-invalid" : "")} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                </div>
                                <div className="pb-3 pt-3 form-inline justify-content-between">
                                    <div className="form-group">
                                        <label htmlFor="price" className="mr-sm-2">Giá sản phẩm</label>
                                        <Field disabled={props.isSubmitting} min={0} onChange={props.handleFormChange} value={props.product.price} name="price" type="number" className={"form-control mr-sm-2" + (errors.price && touched.price ? " is-invalid" : "")} />
                                        <ErrorMessage name="price" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="unit" className="mr-sm-2">Đơn vị</label>
                                        <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.unit} name="unit" type="text" className={"form-control mr-sm-2" + (errors.unit && touched.unit ? " is-invalid" : "")} />
                                        <ErrorMessage name="unit" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="pb-3 pt-3 form-inline justify-content-between">
                                    <div className="form-group">
                                        <label htmlFor="price" className="mr-sm-2">Còn hàng</label>
                                        <Field disabled={props.isSubmitting} onChange={props.handleFormChange} checked={props.product.status} name="status" type="checkbox" className={"form-control mr-sm-2" + (errors.status && touched.status ? " is-invalid" : "")} />
                                        <ErrorMessage name="status" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="unit" className="mr-sm-2">Bán chạy</label>
                                        <Field disabled={props.isSubmitting} onChange={props.handleFormChange} checked={props.product.bestSeller} name="bestSeller" type="checkbox" className={"form-control mr-sm-2" + (errors.bestSeller && touched.bestSeller ? " is-invalid" : "")} />
                                        <ErrorMessage name="bestSeller" component="div" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="characteristic">Đặc tính</label>
                                    <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.characteristic} name="characteristic" type="text" className={"form-control" + (errors.characteristic && touched.characteristic ? " is-invalid" : "")} />
                                    <ErrorMessage name="characteristic" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supplier">Xuất xứ</label>
                                    <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.supplier} name="supplier" type="text" className={"form-control" + (errors.supplier && touched.supplier ? " is-invalid" : "")} />
                                    <ErrorMessage name="supplier" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="color">Màu sắc</label>
                                    <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.color} name="color" type="text" className={"form-control" + (errors.color && touched.color ? " is-invalid" : "")} />
                                    <ErrorMessage name="color" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="warranty">Thông tin bảo hành</label>
                                    <Field disabled={props.isSubmitting} onChange={props.handleFormChange} value={props.product.warranty} name="warranty" type="text" className={"form-control" + (errors.warranty && touched.warranty ? " is-invalid" : "")} />
                                    <ErrorMessage name="warranty" component="div" className="invalid-feedback" />
                                </div>
                                <label for="content">Mô tả chi tiết</label>
                                {
                                    !props.editorIsLoaded &&
                                    <div className="text-center">
                                        <Spinner />
                                    </div>
                                }
                                <TextEditor disabled={props.isSubmitting} onUploadImages={props.onUploadImages} initText={props.product.details} height='600px' onChange={props.editorOnChange} id="content" setLoaded={props.setLoaded} />
                            </Form>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col">
                            <button disabled={props.isSubmitting} onClick={props.cancelOnChange} className="btn btn-danger m-2">Hủy</button>
                            <button disabled={props.isSubmitting} type="submit" form="product-form" className="btn btn-success m-2">Lưu</button>
                        </div>
                    </div>
                </React.Fragment>
            )
            }
        </Formik >
    );
}

export default ProductForm;