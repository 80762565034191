import React from 'react';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from 'yup';
import Spinner from '../Spinner';
import { Component } from 'react';
import axios from '../../axios';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import TextEditor from '../Editor/TextEditor';

class ProductLineForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            productLine: props.productLine,
            categories: props.categories,
            isUploading: false,
            editorIsLoaded: false,
        }
    }

    handleOnChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;
        this.setState({
            ...this.state,
            productLine: {
                ...this.state.productLine,
                [name]: value
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.productLine !== this.props.productLine) {
            this.setState({
                ...this.state,
                productLine: this.props.productLine
            })
        }
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true })
    }

    editorOnChange = (content) => {
        this.setState({
            ...this.state,
            productLine: {
                ...this.state.productLine,
                content: content
            }
        });
    }
    render() {
        return (
            this.state.productLine ?
                <Modal show={this.props.show} backdrop="static" centered onHide={this.props.onClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Dòng sản phẩm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            enableReinitialize="true"
                            initialValues={{
                                id: this.state.productLine ? this.state.productLine.id : '',
                                name: this.state.productLine ? this.state.productLine.name : '',
                                categoryId: this.state.productLine ? this.state.productLine.categoryId : '',
                                description: this.state.productLine ? this.state.productLine.description : '',
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Bạn cần nhập tên'),
                                categoryId: Yup.string().required('Bạn phải chọn loại sản phẩm')
                            })}
                            onSubmit={({ name }, { setStatus, setSubmitting, setErrors }) => {
                                setStatus();
                                setSubmitting(true)
                                if (this.props.isUpdateForm) {
                                    axios.put('/productLines/' + this.state.productLine.id, this.state.productLine)
                                        .then(() => {
                                            setSubmitting(false)
                                            this.props.onUpdateSuccessfully();
                                        }, err => {
                                            toast.error(err.response ? err.response.data.error : 'Some error occurs');
                                            setSubmitting(false)
                                        })
                                } else {
                                    axios.post('/productLines', { name: this.state.productLine.name, description: this.state.productLine.description, content: this.state.productLine.content, categoryId: parseInt(this.state.productLine.categoryId), metaTitle: this.state.productLine.metaTitle, metaImage: this.state.productLine.metaImage })
                                        .then(() => {
                                            setSubmitting(false);
                                            this.props.onAddSuccessfully();
                                        }, err => {
                                            err.response.data.details ? setStatus(err.response.data.details) : setStatus(err.response.data.error);
                                            setSubmitting(false);
                                        })
                                }
                            }}>
                            {({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="name">Tên</label>
                                        <Field name="name" type="text"
                                            value={this.state.productLine.name}
                                            onChange={this.handleOnChange}
                                            className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mt-2 mb-2">
                                        <label htmlFor="categoryId" className="mr-sm-2">Phân loại</label>
                                        {
                                            this.props.isUpdateForm ?
                                                <Field value={this.state.productLine.categoryId} name="categoryId" as="select" disabled={true} className="form-control custom-select mr-sm-2">
                                                    {
                                                        <option value={this.state.productLine.categoryId} key={this.state.productLine.categoryId}>{this.props.categories.find(category => category.id === this.state.productLine.categoryId).name}</option>
                                                    }
                                                </Field> :
                                                <Field onChange={this.handleOnChange} value={this.state.productLine.categoryId} name="categoryId" as="select" className={"form-control custom-select mr-sm-2" + (errors.categoryId && touched.categoryId ? " is-invalid" : "")}>
                                                    <option value="">Chọn</option>
                                                    {
                                                        this.props.categories.map(category => {
                                                            return <option value={category.id} key={category.id}>{category.name}</option>
                                                        })
                                                    }
                                                </Field>
                                        }
                                        <ErrorMessage name="categoryId" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="description">Mô tả</label>
                                        <Field name="description" type="text"
                                            value={this.state.productLine.description}
                                            onChange={this.handleOnChange}
                                            className={"form-control" + (errors.description && touched.description ? " is-invalid" : "")} />
                                        <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="metaTitle">Tiêu đề</label>
                                        <Field name="metaTitle" type="text"
                                            value={this.state.productLine.metaTitle}
                                            onChange={this.handleOnChange}
                                            className={"form-control" + (errors.metaTitle && touched.metaTitle ? " is-invalid" : "")} />
                                        <ErrorMessage name="metaTitle" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="metaImage">Link ảnh meta</label>
                                        <Field name="metaImage" type="text"
                                            value={this.state.productLine.metaImage}
                                            onChange={this.handleOnChange}
                                            className={"form-control" + (errors.metaImage && touched.metaImage ? " is-invalid" : "")} />
                                        <ErrorMessage name="metaImage" component="div" className="invalid-feedback" />
                                    </div>

                                    <div className="form-group text-center">
                                        {isSubmitting && (
                                            <div className="text-center">
                                                <Spinner />
                                            </div>
                                        )}
                                        <button type="button" className="btn btn-danger m-2" data-dismiss="modal" onClick={this.props.onClose} >Hủy</button>
                                        <button type="submit" className="btn btn-primary m-2" disabled={isSubmitting}>Lưu</button>
                                    </div>
                                    {status && (
                                        <div className={"alert alert-danger"}>{status}</div>
                                    )}
                                    <label for="content">Nội dung</label>
                                    {
                                        !this.state.editorIsLoaded &&
                                        <div className="text-center">
                                            <Spinner />
                                        </div>
                                    }
                                    <TextEditor
                                        initText={this.state.productLine.content}
                                        setLoaded={this.setLoaded}
                                        onUploadImages={this.onUploadImages}
                                        onChange={this.editorOnChange}
                                        id="content"
                                        height="700px"
                                    />
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={3000}
                                        hideProgressBar={false}
                                        newestOnTop
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal> : null
        );
    }
}

export default ProductLineForm;