import React, { Component } from 'react';
import axios from '../../../axios';
import TextEditor from '../../../Components/Editor/TextEditor';
import Spinner from '../../../Components/Spinner';
import { withRouter } from 'react-router-dom';
import { imageUtils } from '../../../Services/imageUtils';
import ConfirmModal from '../../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';

class NewsCreatePage extends Component {

    state = {
        title: '',
        description: '',
        image: '',
        previewImage: null,
        imageFile: null,
        content: '',
        editorIsLoaded: false,
        showModal: false,
        isLoading: false,
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true });
    }

    editorOnChange = (content) => {
        this.setState({ ...this.state, content: content });
    }

    cancelCreate = () => {
        this.props.history.goBack();
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showModal: false });
    }

    handleSubmitModalOnClick = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            const imageName = imageUtils.getRandomName();
            imageUtils.uploadImage(this.state.imageFile, imageName)
                .then(rs => {
                    console.log(`Uploaded image ${imageName} at url: ${rs.data}`)
                    const content = this.state.content;
                    axios.post('/news', { title: this.state.title, image: rs.data, description: this.state.description, content: content }).then(() => {
                        this.props.history.goBack();
                    }, err => {
                        this.setState({
                            ...this.state,
                            isLoading: false,
                        })
                        toast.error(err.response ? err.response.data.error : 'Some error occurs');
                    })
                }, err => {
                    toast.error(err.response ? err.response.data.error : 'Some error occurs');
                })
        });
    }

    handleImageChange = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        Resizer.imageFileResizer(
            file,
            500,
            500,
            'JPEG',
            95,
            0,
            file => {
                reader.readAsDataURL(file)
                reader.onloadend = () => {
                    this.setState({
                        ...this.state,
                        imageFile: file,
                        previewImage: reader.result
                    })
                }
            },
            'blob'
        );
    }

    render() {
        return (
            <div className="container" style={{ padding: '20px' }}>
                <div className="row">
                    <div className="col text-center">
                        <div className="text-center">
                            <h3>TIN TỨC</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form>
                            <div className="form-group">
                                <label htmlFor="title">Tiêu đề</label>
                                <input type="text"
                                    className="form-control"
                                    id="title" placeholder="Tiêu đề"
                                    value={this.state.title}
                                    onChange={(e) => this.setState({ ...this.state, title: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="image">
                                    <div>
                                        {
                                            this.state.previewImage &&
                                            <img src={this.state.previewImage} alt="" className="img-thumbnail" />
                                        }
                                    </div>
                                    <input type="file" name="image" onChange={this.handleImageChange} />
                                </label>
                            </div>
                        </form>
                        <form>
                            <div className="form-group">
                                <label htmlFor="description">Tóm tắt</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    placeholder="Tóm tắt"
                                    value={this.state.description}
                                    onChange={(e) => this.setState({ ...this.state, description: e.target.value })} />
                            </div>
                        </form>
                        <label htmlFor="content">Nội dung</label>
                        {
                            !this.state.editorIsLoaded &&
                            <div className="text-center">
                                <Spinner />
                            </div>
                        }
                        <TextEditor
                            initText=''
                            setLoaded={this.setLoaded}
                            height='800px'
                            onChange={this.editorOnChange}
                        />
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <button onClick={this.cancelCreate} className="btn btn-danger m-2">Hủy</button>
                        <button disabled={!this.state.imageFile} type="button" onClick={() => this.setState({ ...this.state, showModal: true })} className="btn btn-success m-2">Lưu</button>
                    </div>
                </div>
                <ConfirmModal
                    title={'Tạo bài viết'}
                    message={'Bạn có muốn lưu bài viết này?'}
                    show={this.state.showModal}
                    onClose={this.handleCloseModalOnClick}
                    isLoading={this.state.isLoading}
                    onAction={this.handleSubmitModalOnClick}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }
}

export default withRouter(NewsCreatePage);