import { storage } from './firebaseService';
import axios from '../axios';

const getAttFromScript = (str, node = 'img', attr = 'src') => {
    let regex = new RegExp('<' + node + ' .*?' + attr + '="(.*?)"', "gi"), result, res = [];
    while ((result = regex.exec(str))) {
        res.push(result[1].replace('amp;', ''));
    }
    return res;
}

const getUnusedImages = (oldImages, newImages) => {
    let res = oldImages.filter(imageURL => newImages.indexOf(imageURL.replace(/["']/g, "")) === -1)
    return res;
}

const getRandomName = () => {
    console.log('name ',Math.random().toString(20).substring(2) + '.jpeg')
    return Math.random().toString(20).substring(2) + '.jpeg';
}

const getImageNameFromURL = (url) => {
    if(url.includes("firebasestorage")) {
        return url.split('.jpeg')[0].split('images%2F')[1] + '.jpeg';
    } else {
        return url.split('.jpeg')[0].split('images/')[1] + '.jpeg';        
    }
}

const removeUnusedImages = (oldImages, newImages) => {
    let images = getUnusedImages(oldImages, newImages);
    images.forEach(imageURL => {
        let name = imageURL.substr(imageURL.indexOf('%2F') + 3, (imageURL.indexOf('?')) - (imageURL.indexOf('%2F') + 3));
        name = name.replace('%20', ' ');
        let ref = storage.ref().child(`images/${name}`);
        ref.delete()
            .then(() => console.log('Deleted:', imageURL))
            .catch(() => console.log('Cannot delete:', imageURL));
    })
}

const uploadImage = (file, name) => {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('fileName', name)
    return axios.post('/images/upload', formData, { headers: { 'content-type': 'multipart/form-data' } })
                .then(rs => {return rs}, 
                    err => {return Promise.reject(err)})
}

const getImageLargeSizeName = (name) => {
    return name.split('.')[0] + '-1000x1000.jpeg';
}

const getLargeImageURL = (url) => {
    if (url.includes('jpeg')) {
        const prefix = url.split('.jpeg')[0];
        const postfix = url.split('.jpeg')[1];
        return prefix + '-1000x1000.jpeg' + postfix;
    }
    const prefix = url.split('.png')[0];
    const postfix = url.split('.png')[1];
    return prefix + '-1000x1000.png' + postfix;
}

const getSubImageName = (name, index) => {
    return name.split('.')[0] + '-sub-' + index + '.jpeg';
}

const getSubImages = (url, subNumber) => {
    let imageList = [{url: null, file: null}, {url: null, file: null}, {url: null, file: null}];
    for(let i = 0; i < subNumber; i++) {
        const prefix = url.split('.jpeg')[0];
        const postfix = url.split('.jpeg')[1];
        imageList[i].url = (prefix + '-sub-' + i + '.jpeg' + postfix);
    }
    return imageList;
}

const getDiplaySubImages = (url, subNumber) => {
    if (subNumber === null) {
        return [];
    }
    let imageList = [];
    for(let i = 0; i < subNumber; i++) {
        const prefix = url.split('.jpeg')[0];
        const postfix = url.split('.jpeg')[1];
        imageList.push(prefix + '-sub-' + i + '.jpeg' + postfix);
    }
    return imageList;
}

export const imageUtils = {
    getAttFromScript,
    getUnusedImages,
    getRandomName,
    removeUnusedImages,
    getImageLargeSizeName,
    getLargeImageURL,
    getSubImageName,
    getSubImages,
    getImageNameFromURL,
    getDiplaySubImages,
    uploadImage
};