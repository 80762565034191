import firebase from 'firebase/app';
import 'firebase/storage';

var firebaseConfig = {
    apiKey: "AIzaSyBHWrzNfQkgFc_o0tbSFMb0ekAwJrvPUZ0",
    authDomain: "curtainstorage.firebaseapp.com",
    databaseURL: "https://curtainstorage.firebaseio.com",
    projectId: "curtainstorage",
    storageBucket: "curtainstorage.appspot.com",
    messagingSenderId: "1034989488319",
    appId: "1:1034989488319:web:1c7d17d867e23d609d9e0a",
    measurementId: "G-MNJKRH4K9Q"
  };

  firebase.initializeApp(firebaseConfig);
  const storage = firebase.storage();
  export {
      storage, firebase as default
  }