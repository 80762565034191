import React from 'react';
import './Toolbar.css';
import { NavLink } from 'react-router-dom';

const Toolbar = (props) => {
    return (
        <div className={props.navState}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <NavLink
                        className="navbar-brand" to={"/"} exact={true}>
                        <img className="logo logo-default" alt="logo" src="/images/logo-red-black.png" />
                        <img className="logo logo-hovered" alt="logo" src="/images/logo-yellow-black.png" />
                    </NavLink>
                    <button className="navbar-toggler" type="button" onClick={props.drawerClickHandler}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link nav-link-ltr" to={"/admin/dashboard"} exact={true}>TRANG CHỦ</NavLink>
                            </li>
                            {
                                props.isAdmin &&
                                <React.Fragment>
                                    <li className="nav-item dropdown">
                                        <NavLink className="nav-link dropdown-toggle nav-link-ltr" aria-haspopup="true" aria-expanded="false" to={"/admin/products"}>SẢN PHẨM</NavLink>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li>
                                                <NavLink className="nav-link" to={"/admin/categories"}>► Phân loại sản phẩm</NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="nav-link" to={"/admin/productLines"}>► Dòng sản phẩm</NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="nav-link" to={"/admin/products"}>► Sản phẩm</NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link nav-link-ltr" to={"/admin/services"}>DỊCH VỤ</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link nav-link-ltr" to={"/admin/news"}>TIN TỨC</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link nav-link-ltr" to={"/admin/about"}>GIỚI THIỆU</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link nav-link-ltr" to={"/contact"}>LIÊN HỆ</NavLink>
                                    </li>
                                    <li className="nav-item" style={{ cursor: 'pointer' }}>
                                        <a href="/#" onClick={props.logout} className="nav-link nav-link-ltr">THOÁT</a>
                                    </li>
                                </React.Fragment>
                            }
                            {
                                !props.isAdmin &&
                                <li className="nav-item" style={{ cursor: 'pointer' }}>
                                    <NavLink className="nav-link nav-link-ltr" to={"/login"}>ĐĂNG NHẬP</NavLink>
                                </li>
                            }
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );

}

export default Toolbar;