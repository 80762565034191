import React, { Component } from 'react';
import './App.css';
import Layout from './Components/hoc/Layout/Layout'
import './assets/fonts/fonts.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './Containers/Home';
import Login from './Containers/Login';
import PrivateRoute from './Components/hoc/PrivateRoute/PrivateRoute';
import AboutEditPage from './Containers/Private/AboutEditPage';
import NewsCreatePage from './Containers/Private/News/NewsCreatePage';
import NewsEditPage from './Containers/Private/News/NewsEditPage';
import NewsAdminPage from './Containers/Private/News/NewsPage';
import ProductsAdminPage from './Containers/Private/Products/ProductsPage';
import ProductCreatePage from './Containers/Private/Products/ProductCreatePage';
import ProductUpdatePage from './Containers/Private/Products/ProductUpdatePage';
import CategoriesPage from './Containers/Private/CategoriesPage';
import ProductLinesPage from './Containers/Private/ProductLinesPage';
import ServicesEditPage from './Containers/Private/ServicesEditPage';

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/admin/about" component={AboutEditPage} />
        <PrivateRoute exact path="/admin/news/create" component={NewsCreatePage} />
        <PrivateRoute path="/admin/news/:id" component={NewsEditPage} />
        <PrivateRoute path="/admin/news" component={NewsAdminPage} />
        <PrivateRoute exact path="/admin/products/create" component={ProductCreatePage} />
        <PrivateRoute path="/admin/products/:id" component={ProductUpdatePage} />
        <PrivateRoute path="/admin/products" component={ProductsAdminPage} />
        <PrivateRoute path="/admin/categories" component={CategoriesPage} />
        <PrivateRoute path="/admin/productLines" component={ProductLinesPage} />
        <PrivateRoute path="/admin/services" component={ServicesEditPage} />
        <Redirect to="/" />
      </Switch>
    );

    return (
      <div className="app">
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

export default App;
