import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <nav className="navbar header-container">
            <a className="navbar-item hotline" href="/#">Hotline: (+84) 0987 289 463</a>
            <ul className="nav navbar-nav ml-auto list-contact">
                <li className="nav-item fa-header">
                    <a className="nav-link" href="/#"><i className="fa fa-facebook"></i></a>
                </li>
                <li className="nav-item fa-header">
                    <a className="nav-link" href="/#"><i className="fa fa-twitter"></i></a>
                </li>
                <li className="nav-item fa-header">
                    <a className="nav-link" href="/#"><i className="fa fa-instagram"></i></a>
                </li>
            </ul>
        </nav>
    );
}

export default Header;