import React, { Component } from 'react';
import axios from '../../../axios';
import Spinner from '../../../Components/Spinner';
import Pagination from '../../../Components/Pagination';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';

class NewsPage extends Component {

    state = {
        isLoaded: false,
        isLoading: false,
        newsList: null,
        showModal: false,
        modalMessage: '',
        target: null,
        page: {
            size: 10,
            pageNumber: 0,
            totalPages: 1,
            sort: 'DSC',
        }
    }


    componentDidMount() {
        axios.get('/news', { params: { page: 0, size: this.state.page.size } })
            .then(rs => {
                this.setState({
                    ...this.state,
                    page: {
                        ...this.state.page,
                        totalPages: rs.data.totalPages,
                    },
                    newsList: rs.data.content,
                    isLoaded: true
                })
            }, err => {
                toast.error(err.response ? err.response.data.error : 'Some error occurs');
            })
    }

    fetchData = () => {
        axios.get('/news', { params: { page: this.state.page.pageNumber, size: this.state.page.size, sort: this.state.page.sort } })
            .then(rs => {
                this.setState({
                    ...this.state,
                    newsList: rs.data.content,
                    isLoading: false,
                    isLoaded: true,
                    page: { ...this.state.page, totalPages: rs.data.totalPages, pageNumber: rs.data.number }
                }, err => {
                    if (err !== undefined) {
                        this.setState({
                            ...this.state,
                            isLoading: false,
                        })
                        toast.error(err.response ? err.response.data.error : 'Some error occurs');
                    }
                })
            })
        this.scrollTop();
    }

    handlePageChange = (page) => {
        this.setState({
            ...this.state,
            isLoading: true,
            page: { ...this.state.page, pageNumber: page.selected }
        }, () => this.fetchData());
    }

    handleSortOnChange = (e) => {
        const sortType = e.target.value === '1' ? 'ASC' : 'DSC'
        this.setState({
            ...this.state,
            isLoading: true,
            page: { ...this.state.page, sort: sortType }
        }, () => this.fetchData());
    }

    handleDeleteOnClick = (id) => {
        this.setState({ ...this.state, target: id, modalMessage: 'Bạn có muốn xóa bài viết số ' + id, showModal: true });
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showModal: false });
    }

    handleSubmitModalOnClick = () => {
        const id = this.state.target;
        this.setState({ ...this.state, isLoading: true }, () => {
            axios.delete('/news/' + id)
                .then(rs => {
                    this.setState({ ...this.state, showModal: false, isLoading: false })
                    this.fetchData();
                    toast.warn('Đã xóa bài viết số : ' + id);

                }, err => {
                    toast.error(err.response ? err.response.data.error : 'Some error occurs');
                    this.setState({ ...this.state, isLoading: false })
                })
        });
    }

    renderTable = () => {
        return (
            <tbody>
                {
                    this.state.newsList.map(news => {
                        return (
                            <tr key={news.id}>
                                <th scope="row">{news.id}</th>
                                <td>{news.title}</td>
                                <td>{news.description}</td>
                                <td>{news.createdAt}</td>
                                <td>
                                    <Link to={'/admin/news/' + news.id}>
                                        <button className="btn btn-sm btn-info ml-2" style={{ width: '50px' }}>Xem</button>
                                    </Link>
                                    <button onClick={() => this.handleDeleteOnClick(news.id)} className="btn btn-sm btn-danger ml-2" style={{ width: '50px' }}>Xóa</button>
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }

    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    render() {
        return (
            <div className="container mt-3">
                <div className="row">
                    <div className="col text-center py-2">
                        <h2>TIN TỨC</h2>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-2 py-2">
                        <Link to="/admin/news/create">
                            <button className="btn btn-sm btn-primary">Thêm</button>
                        </Link>
                    </div>
                    <div className="col-xs-4 pr-2 m-2">
                        <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                                <label className="input-group-text" for="sortGroup">Sắp xếp theo</label>
                            </div>
                            <select className="custom-select" id="sortGroup" onChange={this.handleSortOnChange}>
                                <option value="1">Mới nhất</option>
                                <option value="2">Cũ nhất</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col table-responsive">
                        <table className="table table-hover table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Tiêu đề </th>
                                    <th scope="col">Tóm tắt</th>
                                    <th scope="col">Ngày đăng</th>
                                    <th scope="col">Tác vụ</th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoaded ? this.renderTable() : null
                            }
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        {
                            this.state.isLoaded ? null : <Spinner />
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Pagination pageCount={this.state.page.totalPages} handlePageChange={this.handlePageChange} />
                    </div>
                </div>
                <ConfirmModal
                    title={'Xóa bài viết'}
                    message={this.state.modalMessage}
                    show={this.state.showModal}
                    onClose={this.handleCloseModalOnClick}
                    isLoading={this.state.isLoading}
                    onAction={this.handleSubmitModalOnClick}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }


}

export default NewsPage;