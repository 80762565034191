import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthenticationService } from '../Services/AuthenticationService';
import Spinner from '../Components/Spinner';

class Login extends Component {

    render() {
        return (
            <div className="jumbotron" style={{ height: '100vh' }}>
                <div className="container" style={{ paddingTop: '15%' }}>
                    <div className="col-md-6 offset-md-3">
                        <h2>Login</h2>
                        <Formik
                            initialValues={{
                                username: "",
                                password: "",
                            }}
                            validationSchema={Yup.object().shape({
                                username: Yup.string().required("Username is required"),
                                password: Yup.string().required("Password is required"),
                            })}
                            onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
                                setStatus();
                                AuthenticationService
                                    .login({ username: username, password: password })
                                    .then(
                                        () => {
                                            setSubmitting(false);
                                            this.props.history.push('/')
                                        },
                                        (error) => {
                                            setSubmitting(false);
                                            setStatus(error);
                                        }
                                    );
                            }}>
                            {({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="username">Username</label>
                                        <Field name="username" type="text" className={"form-control" + (errors.username && touched.username ? " is-invalid" : "")} />
                                        <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <Field
                                            name="password"
                                            type="password"
                                            className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")} />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ marginRight: "10px" }}>Login</button>
                                        {isSubmitting && (
                                            <Spinner />
                                        )}
                                    </div>
                                    {status && (
                                        <div className={"alert alert-danger"}>{status}</div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}
export default Login;