import React from 'react';
import './SideDrawer.css';
import { NavLink } from 'react-router-dom';

const SideDrawer = (props) => {
    const renderProducts = () => {
        return (
            props.isLoaded ?
                <ul className="dropdown-menu sub" role="menu" id="drop-menu">
                    {
                        props.products.map((category) => {
                            return (
                                <li className="dropdown" key={category.id}>
                                    <div className="btn-group dropdown">
                                        <NavLink onClick={props.close} to={{ pathname: '/products', search: `?categoryId=${category.id}` }}>{category.name}</NavLink>
                                        <button
                                            style={{ width: '70px' }}
                                            type="button"
                                            className="btn btn-secondary"
                                            data-toggle="collapse"
                                            data-target={"#sub-" + category.id}
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            <i className="fa fa-caret-down" aria-hidden="true" style={{ fontSize: '1.6rem' }}></i>
                                        </button>
                                    </div>
                                    <ul
                                        className="dropdown-menu sub"
                                        role="menu"
                                        id={"sub-" + category.id}>
                                        {
                                            category.productLines.map((line) => {
                                                return (
                                                    <li key={line.id}><NavLink onClick={props.close} to={{ pathname: '/products', search: `?productLineId=${line.id}` }}>{line.name}</NavLink></li>
                                                );
                                            })
                                        }
                                    </ul>
                                </li>
                            );
                        })
                    }
                </ul> : null
        );
    }

    return (
        <nav className={props.show ? 'side-drawer open' : 'side-drawer'}>
            <div className="side-drawer-brand">
                <img className="side-drawer-logo w-100" src="/images/logo-yellow-white.png" alt="Logo"></img>
            </div>
            <ul className="nav sidebar-nav">
                <li>
                    <NavLink to={"/"} exact={true} onClick={props.close}>Trang Chủ</NavLink>
                </li>
                {
                    props.isAdmin ?
                        <React.Fragment>
                            <li className="dropdown">
                                <div className="btn-group dropdown">
                                    <NavLink to="/admin/products" onClick={props.close}>Sản Phẩm</NavLink>
                                    <button style={{ width: '70px' }} type="button" className="btn btn-secondary" data-toggle="collapse" data-target="#sub-admin" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-caret-down" aria-hidden="true" style={{ fontSize: '1.6rem' }}></i>
                                    </button>
                                </div>
                                <ul className="dropdown-menu sub" role="menu" id={"sub-admin"}>
                                    <li><NavLink to="/admin/categories" onClick={props.close}>Phân loại sản phẩm</NavLink></li>
                                    <li><NavLink to="/admin/productLines" onClick={props.close}>Dòng sản phẩm</NavLink></li>
                                    <li><NavLink to="/admin/products" onClick={props.close}>Sản Phẩm</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to="/admin/services" onClick={props.close}>Dịch Vụ</NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/news" onClick={props.close}>Tin Tức</NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/about" onClick={props.close}>Giới Thiệu</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" onClick={props.close}>Liên Hệ</NavLink>
                            </li>
                            <li style={{ cursor: 'pointer' }}>
                                <a href="/#" onClick={props.logout} className="nav-link">Thoát</a>
                            </li>

                        </React.Fragment> :
                        <li>
                            <NavLink to="/login" onClick={props.close}>Đăng nhập</NavLink>
                        </li>

                }
            </ul>
        </nav>
    );

}

export default SideDrawer;