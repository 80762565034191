import React from 'react';
import './CopyRightFooter.css';

const CopyRightFooter = () => {
    return (
        <div className="copyright-wrapper">
            <div className="container">
                <div className="col-md-6">
                    <small>
                        <span>Copyright © 2020-Lung Linh, Inc. All rights reserved.</span>
                    </small>
                </div>
            </div>
        </div>
    );
}

export default CopyRightFooter;