import React, { Component } from 'react';
import axios from '../../../axios';
import TextEditor from '../../../Components/Editor/TextEditor';
import { withRouter } from 'react-router-dom';
import Spinner from '../../../Components/Spinner';
import { imageUtils } from '../../../Services/imageUtils';
import ConfirmModal from '../../../Components/ConfirmModal';
import { ToastContainer, toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';

class NewsEditPage extends Component {
    state = {
        isLoaded: false,
        news: null,
        editorIsLoaded: false,
        previewImage: null,
        showModal: false,
        isLoading: false,
    }
    apiURL = '/news/' + this.props.match.params.id;

    componentDidMount() {
        axios.get(this.apiURL)
            .then(response => {
                this.setState({
                    ...this.state,
                    isLoaded: true,
                    news: response.data,
                    previewImage: response.data.image,
                    imageFile: null,
                });
            });
    }

    setLoaded = () => {
        this.setState({ ...this.state, editorIsLoaded: true });
    }

    onUploadImages = (imgURL) => {
        this.setState({ ...this.state, currentImages: [...this.state.currentImages, imgURL] });
    }

    editorOnChange = (content) => {
        this.setState({
            ...this.state,
            news: {
                ...this.state.news,
                content: content,
            }
        });
    }

    cancelUpdate = () => {
        this.props.history.goBack();
    }

    handleCloseModalOnClick = () => {
        this.setState({ ...this.state, showModal: false });
    }

    handleImageChange = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        Resizer.imageFileResizer(
            file,
            500,
            500,
            'JPEG',
            95,
            0,
            file => {
                reader.readAsDataURL(file)
                reader.onloadend = () => {
                    this.setState({
                        ...this.state,
                        imageFile: file,
                        previewImage: reader.result
                    })
                }
            },
            'blob'
        );
    }
    handleSubmitModalOnClick = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            if (this.state.imageFile) {
                const imageName = imageUtils.getRandomName();
                imageUtils.uploadImage(this.state.imageFile, imageName)
                    .then(rs => {
                        const imageURL = rs.data;
                        const content = this.state.news.content;
                        axios.put(this.apiURL, { ...this.state.news, content: content, image: imageURL })
                            .then((response) => {
                                this.setState({
                                    ...this.state,
                                    content: response.data.content,
                                    isLoading: false,
                                    showModal: false,
                                })
                                toast.success('Cập nhật bài viết thành công');
                            }, err => {
                                toast.error(err.response ? err.response.data.error : 'Some error occurs');
                            })
                    }, err => toast.error(err.response ? err.response.data.error : 'Some error occurs'))
            } else {
                const content = this.state.news.content;
                axios.put(this.apiURL, { ...this.state.news, content: content })
                    .then((response) => {
                        this.setState({
                            ...this.state,
                            content: response.data.content,
                            isLoading: false,
                            showModal: false,
                        })
                        toast.success('Cập nhật bài viết thành công');
                    }, err => {
                        toast.error(err.response ? err.response.data.error : 'Some error occurs');
                    })
            }
        })
    }

    render() {
        return (
            <div className="container" style={{ padding: '20px' }}>
                <div className="row">
                    <div className="col text-center">
                        <div className="text-center">
                            <h3>TIN TỨC</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {
                            this.state.isLoaded &&
                            <React.Fragment>
                                <form>
                                    <div className="form-group">
                                        <label for="title">Tiêu đề</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            placeholder="Tiêu đề"
                                            value={this.state.news.title}
                                            onChange={(e) => this.setState({ ...this.state, news: { ...this.state.news, title: e.target.value } })} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="image">
                                            <div>
                                                {
                                                    this.state.previewImage &&
                                                    <img src={this.state.previewImage} alt="" className="img-thumbnail" />
                                                }
                                            </div>
                                            <input type="file" name="image" onChange={this.handleImageChange} />
                                        </label>
                                    </div>
                                </form>
                                <form>
                                    <div className="form-group">
                                        <label for="description">Tóm tắt</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            placeholder="Tóm tắt"
                                            value={this.state.news.description}
                                            onChange={(e) => this.setState({ ...this.state, news: { ...this.state.news, description: e.target.value } })} />
                                    </div>
                                </form>
                                <label for="content">Nội dung</label>
                                {
                                    !this.state.editorIsLoaded &&
                                    <div className="text-center">
                                        <Spinner />
                                    </div>
                                }
                                <TextEditor
                                    initText={this.state.news.content}
                                    setLoaded={this.setLoaded}
                                    onUploadImages={this.onUploadImages}
                                    onChange={this.editorOnChange}
                                    id="content"
                                    height="700px"
                                />
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <button className="btn btn-danger m-2" onClick={this.cancelUpdate}>Hủy</button>
                        <button type="button" onClick={() => this.setState({ ...this.state, showModal: true })} className="btn btn-success m-2">Lưu</button>
                    </div>
                </div>
                <ConfirmModal
                    title={'Cập nhật bài viết'}
                    message={'Bạn có muốn lưu bài viết'}
                    show={this.state.showModal}
                    onClose={this.handleCloseModalOnClick}
                    isLoading={this.state.isLoading}
                    onAction={this.handleSubmitModalOnClick}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }
}

export default withRouter(NewsEditPage);