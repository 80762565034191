import React, { Component } from 'react';
import CarouselBanner from '../Components/Banners/CarouselBanner/CarouselBanner';

class Home extends Component {

    render() {
        return (
            <React.Fragment>
                <CarouselBanner />
            </React.Fragment>
        );
    }
}

export default Home;