import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from './Spinner';

const ConfirmModal = (props) => {
    return (
        <Modal show={props.show} backdrop="static" centered onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={props.onClose} disabled={props.isLoading}>Hủy</button>
                <button className="btn btn-success" onClick={props.onAction} disabled={props.isLoading}>Xác nhận</button>
                {
                    props.isLoading &&
                    <Spinner />
                }
            </Modal.Footer>
        </Modal>
    );
}
export default ConfirmModal;