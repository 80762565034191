import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { imageUtils } from '../../Services/imageUtils';

const TextEditor = (props) => {
    const handleOnChange = (e) => {
        props.onChange(e.target.getContent());
    }
    const height = props.height;
    return (
        <Editor
            apiKey='12ccksj8vtb4kr5r4lpog1gggsq1w2fi3hyr5kpexi9hptli'
            initialValue={props.initText}
            init={{
                images_upload_handler: (blobInfo, success, failure) => {
                    const imageName = imageUtils.getRandomName();
                    imageUtils.uploadImage(blobInfo.blob(), imageName)
                        .then(rs => {
                            console.log(`Uploaded image ${imageName} at URL: ${rs.data}`);
                            success(rs.data);
                        }, err => {
                            failure(err.response.data.message)
                        })
                },
                fontsize_formats: "8pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt",
                height: height,
                menubar: true,
                plugins: 'preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                image_dimensions: false,
                image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Responsive', value: 'img-fluid' },
                ],
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: false,
                autosave_interval: "30s",
                autosave_prefix: "{path}{query}-{id}-",
                autosave_restore_when_empty: false,
                autosave_retention: "2m",
                image_advtab: true,
                setup: (editor) => {
                    editor.on('LoadContent', () => {
                        props.setLoaded();
                    })
                    editor.on('init', () => {
                        editor.execCommand("fontName", false, "Arial");
                    })
                }
            }}
            onChange={handleOnChange}
        />
    );
}

export default TextEditor;